import React from "react";
import { Spinner } from "react-bootstrap";

export default function SuccessPage() {
  const TransactionProcessing = () => {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "50vh" }}
      >
        <Spinner
          animation="border"
          variant="primary"
          role="status"
          style={{ width: "3rem", height: "3rem" }}
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
        <h3 className="mt-3">Please wait, your transaction is processing...</h3>
      </div>
    );
  };

  return (
    <>
      <div className="container text-center my-5">
        <TransactionProcessing />
      </div>
    </>
  );
}
