import React from "react";

export default function FailedPage() {
  return (
    <>
      <div className="container text-center my-5">
        <div className="card p-4 shadow-sm">
          <div className="card-body">
            <h1 className="text-danger">Transaction Failed</h1>
            <p className="mt-3">
              Unfortunately, your transaction could not be completed.
            </p>
            <div className="my-4">
              <i className="fas fa-times-circle fa-5x text-danger"></i>
            </div>
            <p className="mt-3 mb-0">
              This page will automatically close after 5 seconds.
              <br />
              If you would like to close it manually,{" "}
              <a
                href="/home"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                click here
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
